import TippyTooltipController from './tippy_tooltip_controller'

// controller for dropdown menu using tippy as the base
// this controller extracts some of the common defaults
// we want to use for dropdown and makes it clear what is required for dropdown
export default class extends TippyTooltipController {
  // you can still customize these as controller values
  static values = {
    offset: { type: Array, default: [0, 8] },
    placement: { type: String, default: 'bottom-end' },
    maxWidth: { type: Number, default: 320 },
    // this is the required value to point to the content template
    templateSelector: String
  }

  defaultConfigs () {
    return {
      ...super.defaultConfigs(),
      trigger: 'click',
      interactive: true,
      arrow: false,
      theme: 'base zero-padding',
      appendTo: 'parent',
      // our overlay modal has a zindex of 100
      zIndex: 99,
      // end of preset dropdown defaults
      placement: this.placementValue,
      maxWidth: this.maxWidthValue,
      offset: this.offsetValue
    }
  }
}
