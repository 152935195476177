import BaseController from './base_controller'
import Split from 'split.js'

export default class extends BaseController {
  static targets = ['gutter', 'alignmentSequencesPane', 'rtdsBottomPane']
  static classes = ['loading']

  connect () {
    super.connect()
    const self = this
    Split([this.alignmentSequencesPaneTarget, this.rtdsBottomPaneTarget], {
      sizes: [35, 26],
      minSize: [50, 150],
      snapOffset: 0,
      direction: 'vertical',
      gutter: () => {
        const element = self.gutterTarget.cloneNode(true)
        element.classList.remove('hidden')
        return element
      },
      onDragStart: () => {
        self.toggleLoadingClass()
      },
      onDragEnd: () => {
        self.endDrag()
      }
    })
  }

  /* eslint-disable no-undef */
  endDrag () {
    AlignmentVisual.bottom_panel_visuals.forEach(visual => {
      visual.refresh()
    })
    AlignmentVisual.alignment_sequences.refresh()
    this.toggleLoadingClass()
  }
  /* eslint-enable no-undef */

  toggleLoadingClass () {
    this.alignmentSequencesPaneTarget.classList.toggle(this.loadingClass)
    this.rtdsBottomPaneTarget.classList.toggle(this.loadingClass)
  }
}
