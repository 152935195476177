import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ['newSetTemplate', 'setsList', 'setEntry', 'removeSetBtn']

  initialize () {
    this.gronSetsCount = 1
  }

  addSet () {
    // ensure an unique identifier for new set fields
    const content = this.newSetTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.setsListTarget.insertAdjacentHTML('beforeend', content)
    this.gronSetsCount += 1
    this.toggleSetsDeleteBtnVisibility()
  }

  removeSet () {
    if (this.gronSetsCount > 1) {
      this.gronSetsCount -= 1
      this.toggleSetsDeleteBtnVisibility()
    }
  }

  toggleSetsDeleteBtnVisibility () {
    this.removeSetBtnTargets.forEach(entry => {
      entry.classList.toggle('hidden', this.gronSetsCount <= 1)
    })
  }
}
