import BaseController from './base_controller'
import tippy from 'tippy.js'

export default class extends BaseController {
  static values = {
    templateSelector: String
  }

  connect () {
    super.connect()
    const self = this
    tippy(this.element, {
      allowHTML: true,
      content: self.latestContent(),
      theme: 'base ca-details-popup',
      arrow: false,
      placement: 'top',
      maxWidth: 650,
      interactive: true,
      trigger: 'click',
      onTrigger (instance, _event) {
        instance.setContent(self.latestContent())
      },
      // make sure this is behind our modal container, which is z-index 100
      zIndex: 99,
      appendTo: function () {
        // append to body to avoid clipping issue with some positioned containers
        return document.body
      }
    })
  }

  latestContent () {
    return document.querySelector(this.templateSelectorValue).firstElementChild.cloneNode(true)
  }
}
