import BaseController from './base_controller'

export default class extends BaseController {
  static classes = ['notAllowed']
  static targets = ['permissionFlag']

  permissionFlagTargetConnected (target) {
    const allowed = target.dataset.allowed === 'true'
    this.element.classList.toggle(this.notAllowedClass, !allowed)
  }
}
