import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ['toggleButton']
  static values = {
    formSelector: String
  }

  updateFilter (event) {
    const filterForm = document.querySelector(this.formSelectorValue)
    const field = filterForm.querySelector(event.target.dataset.fieldSelector)
    const target = event.target
    const val = target.type === 'checkbox' ? target.checked : target.value
    field.value = val
    // filter should reset page
    filterForm.querySelector('#page').value = '1'
    filterForm.requestSubmit()
  }

  resetOptions () {
    const event = new Event('tippy-tooltip:reset')
    this.toggleButtonTarget.dispatchEvent(event)
  }
}
