import { Controller } from '@hotwired/stimulus'

// base controller that is suppose to be extended by other controllers
// maps the identifier to the current controller instance for easy access
// https://www.betterstimulus.com/interaction/controller-dom-mapper.html
export default class extends Controller {
  connect () {
    this.element.controllers ||= {}
    this.element.controllers[this.identifier] = this
  }

  disconnect () {
    this.element.controllers ||= {}
    delete this.element.controllers[this.identifier]
  }
}
