import BaseController from './base_controller'
import tippy from 'tippy.js'

export default class extends BaseController {
  static values = {
    allowHTML: { type: Boolean, default: true },
    theme: { type: String, default: 'base' },
    arrow: { type: Boolean, default: true },
    offset: { type: Array, default: [0, 0] },
    placement: { type: String, default: 'top' },
    maxWidth: { type: Number, default: 600 },
    trigger: { type: String, default: 'mouseenter focus' },
    interactive: { type: Boolean, default: false },
    content: String,
    url: String,
    templateSelector: String
  }

  connect () {
    super.connect()

    const configs = this.defaultConfigs()
    if (this.hasUrlValue) {
      configs.onShow = this.fetchContent.bind(this)
    }
    if (this.hasTemplateSelectorValue) {
      configs.content = document.querySelector(this.templateSelectorValue).innerHTML
    }
    this.originalContent = configs.content
    tippy(this.element, configs)
  }

  // event handler to reset tippy tooltip content
  resetContent () {
    const instance = this.element._tippy
    // NOTE: I have to add this to reset tippy's cached content component
    instance.setContent('')
    instance.setContent(this.originalContent)
  }

  fetchContent (instance) {
    fetch(this.urlValue, {
      // fetch doesn't set this header automatically as for jQuery
      // https://github.com/github/fetch/issues/424
      headers: { 'X-Requested-With': 'XMLHttpRequest' }
    }).then((response) => response.text())
      .then((text) => {
        instance.setContent(text)
      })
  }

  defaultConfigs () {
    return {
      content: this.contentValue,
      allowHTML: this.allowHTMLValue,
      theme: this.themeValue,
      arrow: this.arrowValue,
      offset: this.offsetValue,
      placement: this.placementValue,
      maxWidth: this.maxWidthValue,
      trigger: this.triggerValue,
      interactive: this.interactiveValue,
      // for interactive element, 'parent' is a much better choice
      appendTo: this.interactiveValue ? 'parent' : () => document.body
    }
  }
}
