// utility helper functions for range

// check if two ranges, represented as array of two integer for lower and upper bound
// have overlaps
// @param min1 [Number] lower bound of first range
// @param max1 [Number] upper bound of first range
// @param min2 [Number] lower bound of second range
// @param max2 [Number] upper bound of second range
// @return [Boolean]
export function haveOverlap ([min1, max1], [min2, max2]) {
  if (min1 > max1 || min2 > max2) return false

  if (min1 < min2) {
    return max1 >= min2
  } else {
    return max2 >= min1
  }
}
