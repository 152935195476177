import BaseController from './base_controller'
import { haveOverlap } from '../utils/range'

export default class extends BaseController {
  static targets = ['gronGroupEntry', 'header', 'headerTemplate', 'gronGroupVisualConnector']
  static values = {
    checkedGronGroupsState: Object,
    emptyHeaderText: String
  }

  remove () {
    this.dispatch('removed')
    this.element.remove()
  }

  updateGronGroupsState ({ detail: { newCount, identifier, visualContent, positionsRange } }) {
    const newState = this.checkedGronGroupsStateValue
    if (newCount === 0) {
      delete newState[identifier]
    } else {
      newState[identifier] = { count: newCount, visualContent, positionsRange }
    }
    this.checkedGronGroupsStateValue = newState
  }

  checkedGronGroupsStateValueChanged (newStateHash, prevStateHash) {
    // skip initial state change
    if (!prevStateHash) return

    this.headerTarget.innerHTML = this.buildHeaderContent(newStateHash)
    // there are key changes, means there is gron group added or removed
    if (Object.keys(newStateHash).length !== Object.keys(prevStateHash).length) {
      this.updateGronGroupDisableState(newStateHash)
    }
  }

  updateGronGroupDisableState (checkedGronGroupsStateHash) {
    for (const groupEntry of this.gronGroupEntryTargets) {
      const identifier = groupEntry.dataset.conseqAnalysisGronGroupEntryIdentifierValue
      // skip checked entries
      if (identifier in checkedGronGroupsStateHash) continue

      const currMin = parseInt(groupEntry.dataset.conseqAnalysisGronGroupEntryRangeMinValue)
      const currMax = parseInt(groupEntry.dataset.conseqAnalysisGronGroupEntryRangeMaxValue)
      const disabled = Object.values(checkedGronGroupsStateHash).some(
        ({ positionsRange }) => haveOverlap([currMin, currMax], positionsRange)
      )
      groupEntry.dataset.conseqAnalysisGronGroupEntryDisabledValue = disabled
    }
  }

  buildHeaderContent (checkedGronGroupsStateHash) {
    if (Object.keys(checkedGronGroupsStateHash).length === 0) return this.emptyHeaderTextValue

    let newContent = this.headerTemplateTarget.innerHTML
    const totalVariationsCount = this.collectTotalVariationsCount(checkedGronGroupsStateHash)
    const allGronGroupVisuals = this.collectAllGronGroupVisuals(checkedGronGroupsStateHash)
    newContent = newContent.replace(/COUNT/g, totalVariationsCount)
    newContent = newContent.replace(/VISUALS/g, allGronGroupVisuals)
    if (totalVariationsCount > 1) newContent = newContent.replace(/Sequence/g, 'Sequences')
    return newContent
  }

  collectTotalVariationsCount (checkedGronGroupsStateHash) {
    return Object.values(checkedGronGroupsStateHash).reduce((prev, { count }) => prev * count, 1)
  }

  collectAllGronGroupVisuals (checkedGronGroupsStateHash) {
    const allVisuals = []
    Object.values(checkedGronGroupsStateHash).reduce((prev, { visualContent }) => {
      prev.push(visualContent)
      return prev
    }, allVisuals)
    return allVisuals.join(this.gronGroupVisualConnectorTarget.innerHTML)
  }
}
