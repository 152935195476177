import BaseController from './base_controller'

export default class extends BaseController {
  static classes = ['conflictIndicatorHidden']
  static targets = ['singleGronCheckBox', 'bulkCheckBox', 'conflictIndicator', 'visualTemplate']
  static values = {
    gronsCount: Number,
    checkedGronsCount: Number,
    checkedExpandedVariationsCount: Number,
    rangeMin: Number,
    rangeMax: Number,
    disabled: Boolean,
    identifier: String
  }

  toggleSingleGron (event) {
    const target = event.currentTarget
    this.updateCheckedCountValues(target.checked, false, target.dataset.expandedVariationsCount)
    this.updateBulkCheckboxState()
  }

  bulkToggle (event) {
    const target = event.currentTarget
    this.updateCheckedCountValues(target.checked, true, target.dataset.expandedVariationsCount)
    this.updateSingleGronCheckBoxState(target.checked)
  }

  updateSingleGronCheckBoxState (checked) {
    this.singleGronCheckBoxTargets.forEach(checkbox => { checkbox.checked = checked })
  }

  updateBulkCheckboxState () {
    const checkedCount = this.checkedGronsCountValue
    this.bulkCheckBoxTarget.checked = checkedCount === this.gronsCountValue
    this.bulkCheckBoxTarget.indeterminate = checkedCount < this.gronsCountValue && checkedCount > 0
  }

  updateCheckedCountValues (checked, isBulk, expandedVariationsCount) {
    if (isBulk) {
      this.checkedGronsCountValue = checked ? this.gronsCountValue : 0
      this.checkedExpandedVariationsCountValue = checked ? expandedVariationsCount : 0
    } else {
      this.checkedGronsCountValue += (checked ? 1 : -1)
      this.checkedExpandedVariationsCountValue += (checked ? 1 : -1) * expandedVariationsCount
    }
  }

  checkedExpandedVariationsCountValueChanged (value) {
    const visualContent = value === 0 ? '' : this.visualTemplateTarget.innerHTML.replace(/COUNT/g, value)
    this.dispatch(
      'checkedGronsChanged',
      {
        detail: {
          newCount: value,
          identifier: this.identifierValue,
          visualContent,
          positionsRange: [this.rangeMinValue, this.rangeMaxValue]
        }
      }
    )
  }

  disabledValueChanged (value) {
    this.element.disabled = value
    this.bulkCheckBoxTarget.disabled = value
    this.conflictIndicatorTarget.classList.toggle(this.conflictIndicatorHiddenClass, !value)
  }
}
