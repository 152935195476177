import BaseController from './base_controller'

// controller to add disable_with behaviors for turbo-eabled element
// this controller should be added to the element that fires the turbo request
// see https://turbo.hotwired.dev/reference/events for reference
// the actual element to disable could be different, and should be set by the disable target
export default class extends BaseController {
  static targets = ['disable']
  static values = {
    disableMessage: { type: String, default: 'Processing...' }
  }

  connect () {
    super.connect()
    this.originalContent = this.disableTarget.innerHTML
    this.element.addEventListener('turbo:before-fetch-request', this.disable.bind(this))
    this.element.addEventListener('turbo:before-fetch-response', this.restore.bind(this))
  }

  disconnect () {
    super.disconnect()
    this.restore()
    this.element.removeEventListener('turbo:before-fetch-request', this.disable.bind(this))
    this.element.removeEventListener('turbo:before-fetch-response', this.restore.bind(this))
  }

  disable () {
    this.disableTarget.innerHTML = this.disableMessageValue
    this.disableTarget.disabled = true
  }

  restore () {
    this.disableTarget.innerHTML = this.originalContent
    this.disableTarget.disabled = false
  }
}
