import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ['interactionLayerContainer']
  static values = {
    dataPath: String,
    alignmentLength: { type: Number, default: 0 },
    seqPredSize: { type: Number, default: 0 }
  }

  connect () {
    super.connect()
    fetch(this.dataPathValue, {
      // fetch doesn't set this header automatically as for jQuery
      // https://github.com/github/fetch/issues/424
      headers: { 'X-Requested-With': 'XMLHttpRequest' }
    }).then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response.json()
    }).then(this.success.bind(this))
      .catch((error) => console.error(error))
  }

  /* eslint-disable no-undef */
  success (json) {
    AlignmentVisual.seq_prediction_visual.alignment_length = this.alignmentLengthValue
    AlignmentVisual.seq_prediction_visual.number_of_records = this.seqPredSizeValue
    AlignmentVisual.seq_prediction_visual.refresWithNewData(json)
  }
  /* eslint-enable no-undef */
}
