import BaseController from './base_controller'

export default class extends BaseController {
  connect () {
    super.connect()
    this.showFrameElement = document.querySelector('#consequence_analysis_show')
  }

  change () {
    const pathParts = this.showFrameElement.getAttribute('src').split('/')
    pathParts[pathParts.length - 1] = this.element.value
    this.showFrameElement.setAttribute('src', pathParts.join('/'))
  }
}
