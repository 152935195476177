import BaseController from './base_controller'

export default class extends BaseController {
  static values = {
    path: String,
    targetSelectors: Array,
    waitTime: { type: Number, default: 2000 }
  }

  initialize () {
    this.pollingTimer = null
    this.parser = new DOMParser()
  }

  connect () {
    super.connect()
    this.pollingTimer = setTimeout(() => {
      this.sendRequest()
    }, this.waitTimeValue)
  }

  disconnect () {
    super.disconnect()
    if (this.pollingTimer) {
      clearTimeout(this.pollingTimer)
      this.pollingTimer = null
    }
  }

  sendRequest () {
    fetch(this.pathValue)
      .then((response) => response.text())
      .then((text) => {
        this.repalceElements(text)
      })
  }

  repalceElements (text) {
    const html = this.parser.parseFromString(text, 'text/html')

    if (this.hasTargetSelectorsValue) {
      this.targetSelectorsValue.forEach(selector => {
        const element = document.querySelector(selector)
        if (element) {
          element.replaceWith(html.querySelector(selector))
        } else {
          console.error(`Cannot find element with selector: ${selector}`)
        }
      })
    } else {
      this.element.replaceWith(html.body.firstChild)
    }
  }
}
