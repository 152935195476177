// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '@hotwired/turbo-rails'
require('@rails/activestorage').start()
require('channels')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
// eslint-disable-next-line no-unused-vars
const imagePath = (name) => images(name, true)

// Import fontawesome
// we don't import the js from fontawesome because that will convert i tag to svg
require('@fortawesome/fontawesome-free/css/all.css')

// import chosen-js
require('chosen-js/chosen.jquery.js')
require('chosen-js/chosen.css')

// import tippy.js
require('tippy.js')
require('tippy.js/dist/tippy.css')

// split pane
require('split.js')

// import jquery-ui styles
require('jquery-ui-dist/jquery-ui.css')

// stylesheets
require('stylesheets/application')

// import all stimulus controllers
require('controllers')
